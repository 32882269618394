import React from "react";
import platform3Screens from "../static/images/platform_3screens.png";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Button } from "../components/Button";
import { Monitors } from "../components/Monitors";
import { Routes } from "../components/Routes";
import { RPKI } from "../components/RPKI";
import { StartToday } from "../components/StartToday";
import { GraphiQL } from "../components/GraphiQL";
import { LiveDataUI } from "../components/LiveDataUI";
import { Alerting } from "../components/Alerting";
import { Telemetry } from "../components/Telemetry";
import { LearnMore } from "../components/LearnMore";

const PlatformPage = () => {
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Platform | Code BGP</title>
        <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/cisco-announces-acquisition-of-codebgp" />
      </head>

      <body>
      </body>
    </html>
  );
};

export default PlatformPage;
